import React, { Component } from 'react'

import AboutMeImg from '../assets/aboutMe.jpg';

import { AiFillHtml5, AiFillGithub } from 'react-icons/ai';
import { DiCss3, DiSass, DiReact, DiTerminal } from 'react-icons/di';
import { FaDatabase, FaGitSquare } from 'react-icons/fa';
import { SiJavascript, SiGatsby, SiBootstrap, SiGraphql, SiNodeDotJs, SiMongodb, SiMysql, SiVisualstudiocode } from 'react-icons/si';

export class About extends Component {
    render() {
        return (
            <section className="aboutMe">
                <div className='about-container'>
                    <div className='row'>
                        <div className='col-3-about'>
                            <img src={AboutMeImg} alt="" class="aboutMeImg" />
                        </div>
                        <div className='col-9-about'>
                            <div className='about-content'>
                                <h2 class="sectionTitle sectionTitle-About">
                                 </h2>
                                <p class="abouttitle">
                                    <span>Designer & developer</span>
                                </p>

                                <div class="aboutMeBody">
                                    <p>I am a front-end developer from Staffordshire. I graduated from Staffordshire university with a Computer Science degree.
                                     I gained a lot of programming fundamentals and problem-solving skills from this. Since leaving University I have been focusing more on web development.</p><br/>
                                     <p>I have been enjoying the front-end of things, learning HTML, CSS, and JavaScript. I have recently been focusing on JavaScript libraries and have been using React.js.
                                     I have an interest in learning more skills for back-end development and have been using Node.js for more complex projects.</p><br/>
                                     <p>When I’m not coding, I enjoy gaming 🎮  , LOVE cooking 👨‍🍳  , enjoy American football 🏈 .</p>
                                </div>

                                <p className='abouttitletwo'><span>Languages & Tools</span></p>
                                <div className='tools'>
                                    <div className='tools-row'>
                                        <span><AiFillHtml5 /></span>
                                        <span><DiCss3 /></span>
                                        <span><SiJavascript /></span>
                                        <span><DiSass /></span>
                                    </div>
                                    <div className='tools-row'>
                                    <span><DiReact /></span>
                                    <span><SiGatsby /></span>
                                    <span><SiBootstrap /></span>
                                    <span><SiGraphql /></span>
                                    </div>
                                    <div className='tools-row'>
                                    <span><SiNodeDotJs /></span>
                                    <span><SiMongodb /></span>
                                    <span><SiMysql /></span>
                                    <span><FaDatabase /></span>
                                    </div>
                                    <div className='tools-row'>
                                    <span><SiVisualstudiocode /></span>
                                    <span><AiFillGithub /></span>
                                    <span><FaGitSquare /></span>
                                    <span><DiTerminal /></span>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About
