import React, { Component } from 'react';
import { FiChevronsRight } from 'react-icons/fi';

export class Experience extends Component {

    state = {job1: true, job2: false};

    jobBtn1 = (e) => {
        this.setState({ job1: true, job2: false});
    }

    jobBtn2 = (e) => {
        this.setState({ job1: false, job2: true});
    }

    render() {  
        let border;
        let border2;

        if (this.state.job1) {
            border = 'jobs-button-border'
        } else {
            border = 'job-btn'
        }

        if (this.state.job2) {
            border2 = 'jobs-button-border'
        } else {
            border2 = 'job-btn'
        }
        return (
            <section className='myExperience'>
                <h2 className="sectiontitle sectionTitle-Experience"></h2>
                <div className='experience-container'>
                    <div className='row'>
                            <div className='experience-button-content'>
                                <div className='button-section'>
                                    <div className={border}>
                                        <button onClick={this.jobBtn1}>PMUK</button>
                                    </div>
                                    <div className={border2}>
                                        <button onClick={this.jobBtn2}>Freelance</button>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className='row'>
                                <div className='experience-content'>
                                    <div className='job-section'>
                                        {this.state.job1 === true && 
                                        <div>
                                            <h2>Technical Analyst</h2>
                                            <h3 className='jobtitle'><span>PMUK</span></h3>
                                            <h3>June 2019 - Present</h3>
                                            <div className='jobdesc'>
                                                <p className='jobdesc'><span><FiChevronsRight /></span>&nbsp;&nbsp; Maintain and update multiple servers.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Maintain and update website and web portal.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Develop new functionality.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Suopport the platform with troubleshooting and ticket assistance.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Optimise application for maximum speed.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Maintain a relationship with the client at a professional level.</p>
                                            </div>
                                        </div> 
                                        }
                                        {this.state.job2 === true && 
                                        <div>
                                            <h2>Designer & Developer</h2>
                                            <h3 className='jobtitle'><span>Freelance</span></h3>
                                            <h3>Present</h3>
                                            <div className='jobdesc'>
                                                <p className='jobdesc'><span><FiChevronsRight /></span>&nbsp;&nbsp; Maintain and imporve websites.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Design responsive, mobile friednly websites and features.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Create quality mockups and prototypes.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Ensure high quality graphics and brand consitency.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Optimise applications for maximum speed.</p>
                                                <p><span><FiChevronsRight /></span>&nbsp;&nbsp; Maintain a relationship with the client at a professional level.</p>
                                            </div>
                                        </div> 
                                        }
                                    </div>
                                </div>
                        </div>
                      </div>
            </section>
        )
    }
}

export default Experience;
