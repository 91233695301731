import React, { Component } from 'react';

import { FaDesktop, FaGithub } from 'react-icons/fa';


export class Projectt extends Component {
    render() {
        const { img, title, description, stack1, stack2, stack3, stack4, srcServer, srcGit } = this.props;
        return (
            <section>
                <div class="grid">
                    <div class="flip-card">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                            <div class="flip-card-background-one">
                                <img src={img} alt="GithubLookup"/>
                            </div>
                            </div>
                            <div class="flip-card-back-one">
                                <div class="flip-card-description">
                                    <h1 className='project-header'>{title}</h1>
                                    <p className='project-desc'>{description}</p>
                                    <h3 className='project-stack'>{stack1}</h3>
                                    <h3 className='project-stack'>{stack2}</h3>
                                    <h3 className='project-stack'>{stack3}</h3>
                                    <h3 className='project-stack'>{stack4}</h3>

                                    <ul className='flip-card-ul'>
                                        <li>
                                            <a className='project-link' href={srcServer}><FaDesktop /></a>
                                        </li>
                                        <li>
                                            <a className='project-link' href={srcGit}><FaGithub /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Projectt
