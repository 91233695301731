import React from 'react'

const Services =() => {
    return (
        <section className='myServices'>
        <h2 className="sectiontitle sectionTitle-Services"></h2>
    <div className="services">
        <div className="grid-4">
            <div className="card-col">
                <div className="card">
                    <div className="card-inner">
                        <div className="card-icon"><i className="far fa-window-maximize fa-2x"></i></div>
                        <h3 className="card-title">Front End</h3><hr/>
                        <div className="card-skills">
                        <p>HTML5</p>
                        <p className='pt'>CSS</p>
                        <p className='pt'>JavaScript</p>
                        <p className='pt'>Bootstrap | React</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card-col">
                <div className="card">
                    <div className="card-inner">
                        <div className="card-icon"><i className="fas fa-code fa-2x"></i></div>
                        <h3 className="card-title">Server</h3><hr/>
                        <div className="card-skills">
                        <p>Python</p>
                        <p className='pt'>Node</p>
                        <p className='pt'>C#</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card-col">
                <div className="card">
                    <div className="card-inner">
                        <div className="card-icon"><i className="fas fa-database fa-2x"></i></div>
                        <h3 className="card-title">Database</h3><hr/>
                        <div className="card-skills">
                        <p>MongoDB</p>
                        <p className='pt'>MySQL</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card-col">
                <div className="card">
                    <div className="card-inner">
                        <div className="card-icon"><i className="fas fa-cloud fa-2x"></i></div>
                        <h3 className="card-title">Deployment</h3><hr/>
                        <div className="card-skills">
                        <p>Netlify</p>
                        <p className='pt'>Github</p>
                        <p className='pt'>Git</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    )
}

export default Services
