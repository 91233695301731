import React, { Component } from 'react';

export class logo extends Component {
    constructor(props) {
        super(props);
        this.state = {showText: true, showToggle: false};
     
        setInterval(() => {
          this.setState(previousState => {
            return { showText: !previousState.showText };
          });
        },
        1000);
      }
    render() {
        let display = this.state.showText ? this.props.text : '_';
        return (
            <div className='navbar-left-logo'>
                <p class="logo-text">&lt;/Jack Evans&gt;{display}</p>
            </div>
        )
    }
}

export default logo
