import React, { Component } from 'react';
import Project from '../components/Project';

import GithubLookup from '../assets/githubLookup.png';
import MovieLookup from '../assets/movieLookup.png';
import expandingCards from '../assets/expandingCards.png';
import Pokedex from '../assets/Pokedex.png';

export class Projects extends Component {
    render() {
        return (
            <section className='myProjects'>
                <div className='margin-top'>
                <h2 className="sectiontitle sectionTitle-Projectspage"></h2>
                <div className='project-container'>
                    <div className='grid-2-big'>
                    <Project 
                                title={'Github Lookup'}
                                img={GithubLookup} 
                                description={'Using the github API i have created an application in which you can search a users gitHub account and retrieve data back from their profile in an easy readable format.'}
                                stack1={'React'}
                                stack2={'CSS'}
                                stack3={'JavaScript'}
                                srcServer={'https://jre-project-github-lookup.netlify.app/'}
                                srcGit={'https://github.com/jackrevans/Github-Lookup'}
                            />
                            <Project
                                title={'Movie Lookup'}
                                img={MovieLookup}
                                description={'Using a movie API i have created a landing page with the most popular 30 movies and a searchbar to search movies which you would like more info on.'}
                                stack1={'HTML'}
                                stack2={'CSS'}
                                stack3={'JavaScript'}
                                srcServer={'https://jre-project-movie-lookup.netlify.app/'}
                                srcGit={'https://github.com/jackrevans/MovieLookup'}
                            />
                    </div>
                    <br/>
                    <div className='grid-2-big'>
                            <Project 
                                title={'Expanding Cards'}
                                img={expandingCards} 
                                description={'A simple application witch shows 5 images cointained within cards, with an opening and closing transition on clicking.'}
                                stack1={'HTML'}
                                stack2={'CSS'}
                                stack3={'JavaScript'}
                                srcServer={'https://jre-project-expanding-cards.netlify.app/'}
                                srcGit={'https://github.com/jackrevans/expanding-cards'}
                            />
                            <Project
                                title={'Pokedex'}
                                img={Pokedex}
                                description={'A simple pokemon stand-off game. Which uses the pokemon API to retrieve the data about the pokemon. Randomising each players hand and calculating a winner based on each hands stats.'}
                                stack1={'React'}
                                stack2={'CSS'}
                                stack3={'Javascript'}
                                srcServer={'https://jre-project-movie-lookup.netlify.app/'}
                                srcGit={''}
                            />
                    </div>
                </div>
            </div>
          </section>
        )
    }
}

export default Projects
