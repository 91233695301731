import React, { Component } from 'react';
import { FaCodepen, FaTwitter, FaLinkedinIn, FaGithub } from 'react-icons/fa';

export class Footer extends Component {
    render() {
        return (
            <footer class="footer">
                <a href="mailto:jackrevans06@gmail.com" class="footerLink">jackrevans06@gmail.com</a>

                <ul className='footer-ul'>
                    <li>
                        <a href="https://twitter.com/jackevans_dev"><FaTwitter /></a>
                    </li>
                    <li>
                        <a href="https://codepen.io/jackevans_dev"><FaCodepen /></a>
                    </li>
                    <li>
                        <a href="https://github.com/jackrevans"><FaGithub /></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/jack-evans-b4379315a/"><FaLinkedinIn /></a>
                    </li>
                </ul>

                <p>&copy;{new Date().getFullYear()} Jack Evans. All rights reserved.</p>
            </footer>
        )
    }
}

export default Footer
