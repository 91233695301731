import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';

import PageLinks from './Links';

import Logo from './logo';

const Nav = ({ toggleSidebar }) => {
    return (
        <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Logo />
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <GiHamburgerMenu />
          </button>
        </div>
        <PageLinks styleClass="nav-links"></PageLinks>
      </div>
    </nav>
        )
    }    

export default Nav
