import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Project from './Project';

import GithubLookup from '../assets/githubLookup.png';
import MovieLookup from '../assets/movieLookup.png';

export class Projects extends Component {
    render() {
        return (
            <section className='myProjects'>
                <h2 className="sectiontitle sectionTitle-Projects"></h2>
                <div className='project-container'>
                    <div className='grid-2-big'>
                            <Project 
                                title={'Github Lookup'}
                                img={GithubLookup} 
                                description={'Using the github API i have created an application in which you can search a users gitHub account and retrieve data back from their profile in an easy readable format.'}
                                stack1={'React'}
                                stack2={'CSS'}
                                stack3={'JavaScript'}
                                srcServer={'https://jre-project-github-lookup.netlify.app/'}
                                srcGit={'https://github.com/jackrevans/Github-Lookup'}
                            />
                            <Project
                                title={'Movie Lookup'}
                                img={MovieLookup}
                                description={'Using a movie API i have created a landing page with the most popular 30 movies and a searchbar to search movies which you would like more info on.'}
                                stack1={'HTML'}
                                stack2={'CSS'}
                                stack3={'JavaScript'}
                                srcServer={'https://jre-project-movie-lookup.netlify.app/'}
                                srcGit={''}
                            />
                    </div>
                    <br/><br/><br/><br/>
                    <div className="project-button">
                        <div className='header-button'>
                            <Link to='/projects/' className='btn btn-outline'>See all projects</Link>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Projects
