import React from 'react';
import Selfie from '../assets/Selfie.png';
import Pdf from '../assets/curriculumVitae.pdf';
import { FaCodepen, FaTwitter, FaGithub, FaLinkedinIn } from 'react-icons/fa';

const Banner = () => {
    return (
        <header className='header'>
            <div className='container'>
                <div className='row'>
                    <div className='col-6'>
                        <div className='header-content'>
                            <div className='header-section'>
                                <ul className='header-ul'>
                                <li>
                                    <a href="https://twitter.com/jackevans_dev"><FaTwitter /></a>
                                </li>
                                <li>
                                    <a href="https://codepen.io/jackevans_dev"><FaCodepen /></a>
                                </li>
                                <li>
                                    <a href="https://github.com/jackrevans"><FaGithub /></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/in/jack-evans-b4379315a/"><FaLinkedinIn /></a>
                                </li>
                                </ul>
                                <h1>Hello... I'm Jack Evans</h1>
                                <p>Designer & Developer</p>
                                <div className='header-button'>
                                    <a href={Pdf} className='btn btn-outline' target='_blank'>Curiculum Vitae</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6-hide'>
                        <div className='header-content'>
                            <div className='img-section'>
                                <img className='banner-img' src={Selfie} alt="Jack"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Banner
