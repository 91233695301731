import React, { Component } from 'react';

import Header from '../components/Banner';
import Services from '../components/Services';
import Experience from '../components/Experience';
import Projects from '../components/Featured-Projects';

export class Home extends Component {
    render() {
        return (
            <div>
                <Header />
                <Services />
                <Experience />
                <Projects />
            </div>
        )
    }
}

export default Home
